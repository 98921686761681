
import Button from '@/components/Button.vue'
import Holder from '@/provider/Holder.vue'
import { getBindFormSchema } from '@/events/christmas/address'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { Data } from '@/types'
import Form from 'common/form/Form.vue'
import FInput from 'common/form/inputs/FormInput.vue'
import Textarea from 'common/form/inputs/Textarea.vue'
import { defineComponent, ref, shallowRef } from 'vue'
import { useRouter } from 'vue-router'
import { readInputInfo, useEditAddress } from '@/events/christmas/christmas.api'

export default defineComponent({
  name: 'AddressEdit',
  components: { Button, Form, FInput, PageWithHeader, Textarea, Holder },
  setup () {
    const { request, progress } = useEditAddress()
    const router = useRouter()
    const form = ref<HTMLFormElement | null>(null)
    const schema = shallowRef({})
    const loaded = shallowRef(false)
    const formModel = shallowRef({
      recipientAddress: '',
      recipientCity: '',
      recipientMobile: '',
      recipientName: '',
      recipientProvince: '',
      recipientZipCode: '',
    })

    const getInfo = () => {
      readInputInfo().then(res => {
        formModel.value = res
        schema.value = getBindFormSchema(res)
        loaded.value = true
      })
    }
    getInfo()
    return {
      form,
      formModel,
      schema,
      loaded,
      bind (data: Data) {
        return request(data).then(() => {
          router.back()
        })
      },
      progress,
      submit () {
        (form.value as HTMLFormElement).submit()
      },
    }
  },
})
