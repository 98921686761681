
import * as R from 'ramda'
import FormItem from '../FormItem.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Textarea',
  components: { FormItem },
  setup (props, ctx) {
    return {
      attrs: R.pick(['name', 'text'], ctx.attrs),
    }
  },
})
