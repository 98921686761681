import { AddInfoSchema } from '@/events/christmas/christmas.api'
export const getBindFormSchema = (data : AddInfoSchema) => {
  return {
    recipientName: {
      pattern: 'noSpecial',
      default: data.recipientName,
    },
    recipientMobile: {
      pattern: /^\d{9,12}$/,
      default: data.recipientMobile,
    },
    recipientCity: {
      pattern: 'noSpecial',
      default: data.recipientCity,
    },
    recipientProvince: {
      pattern: 'noSpecial',
      default: data.recipientProvince,
    },
    recipientZipCode: {
      pattern: 'noSpecial',
      default: data.recipientZipCode,
    },
    recipientAddress: {
      pattern: 'noSpecial',
      default: data.recipientAddress,
    },
  }
}
